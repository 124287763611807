import * as React from "react";
import {FormSection, required, Text} from "components/form";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import OptionalFormBaseComponent, {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import repository from "client/repository";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {cloneDeep} from "lodash";
import {RouteComponentProps} from "react-router-dom";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import Sensitive, {ObfuscatedPlaceholder} from "components/Sensitive/Sensitive";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import { CreateDynamicWorkerServiceResource } from "client/resources/createDynamicWorkerServiceResource";
import { createEmptySensitiveValue } from "shared/sensitiveValueHelper";
import buildDynamicWorkerServiceBreadcrumbTitle from "./buildDynamicWorkerServiceBreadcrumbTitle";
import {UpdateDynamicWorkerServiceResource} from "../../client/resources/updateDynamicWorkerServiceResource";
import {instancesRouteLinks} from "../instances/instancesRouteLinks";

type Props = RouteComponentProps<{ id: string }>;

interface State extends OptionalFormBaseComponentState<UpdateDynamicWorkerServiceResource> {
    redirectTo?: string;
}

export class EditDynamicWorkerService extends OptionalFormBaseComponent<Props, State, UpdateDynamicWorkerServiceResource> {
    private service: DynamicWorkerServiceResource;
    
    
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        
        this.service = await repository.DynamicWorkerServices.get(id);

        const model =  {
                Name: this.service.Name,
                Username: this.service.Username,
                ApiKey: createEmptySensitiveValue(),
                SeqDashboardId: this.service.SeqDashboardId,
                SeqDashboardName: this.service.SeqDashboardName,
                AppRegistrationAudience: this.service.AppRegistrationAudience,
            };
        
        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });
    }

    render() {

        const state = this.state;

        if (state.redirectTo) {
            return <InternalRedirect to={state.redirectTo}/>;
        }

        const menuItems = [
            OverflowMenu.deleteItemDefault("Dynamic Worker Service", () => this.delete())
        ];

        const dynamicWorkerServiceId = this.props.match.params.id;
        const actionName = "Update Dynamic Worker Service";
        
        
        return <FormPaperLayout
            title={actionName}
            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.service)}
            busy={state.busy}
            errors={state.errors}
            model={state.model}
            cleanModel={state.cleanModel}
            saveButtonLabel={actionName}
            saveText="Dynamic Worker Service updated"
            saveButtonBusyLabel={"Saving"}
            expandAllOnMount={true}
            overFlowActions={menuItems}
            onSaveClick={this.handleSaveClick}
        >
            {state.model && this.getBody()}
        </FormPaperLayout>;
    }

    getBody() {
        const model = this.state.model;

        return <div>
            <ExpandableFormSection
                errorKey="Name"
                title="Name"
                summary={model.Name ? Summary.summary(model.Name) : Summary.placeholder("No name")}
                help={<span>Enter the display name for this service.</span>}>
                <Text label="Name"
                      value={model.Name}
                      onChange={Name => this.setModelState({Name})}
                      validate={required("Please enter the name to use for this Dynamic Worker Service")}
                      error={this.getFieldError("Name")}
                      autoFocus={true}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="AppRegistrationAudience"
                title="App Registration Audience"
                summary={model.AppRegistrationAudience ? Summary.summary(model.AppRegistrationAudience) : Summary.placeholder("No app registration audience")}
                help={<span>Enter the Application ID URI for this service's App Registration - see the Overview page in Azure Portal for this App Registration</span>}>
                <Text label="AppRegistrationAudience"
                      value={model.AppRegistrationAudience}
                      onChange={AppRegistrationAudience => this.setModelState({AppRegistrationAudience})}
                      validate={required("Please enter the App Registration Audience for this service")}
                      error={this.getFieldError("AppRegistrationAudience")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Username"
                title="Username"
                summary={model.Username ? Summary.summary(model.Username) : Summary.placeholder("No Username")}
                help={<span>Enter the username for this service.</span>}>
                <Text label="Username"
                      value={model.Username}
                      onChange={Username => this.setModelState({Username})}
                      validate={required("Please enter the username for this service")}
                      error={this.getFieldError("Username")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="ApiKey"
                title="ApiKey"
                summary={model.ApiKey && model.ApiKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No ApiKey")}
                help={<span>Enter the api key for this service.</span>}>

                <Sensitive label="ApiKey"
                           value={model.ApiKey}
                           onChange={ApiKey => this.setModelState({ApiKey})}
                           error={this.getFieldError("ApiKey")}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="SeqDashboardName"
                title="Seq Dashboard Name"
                summary={model.Username ? Summary.summary(model.SeqDashboardName) : Summary.placeholder("No dashboard name")}
                help={<span>Enter the Seq Dashboard Name for this service.</span>}>
                <Text label="SeqDashboardName"
                      value={model.SeqDashboardName}
                      onChange={SeqDashboardName => this.setModelState({SeqDashboardName})}
                      validate={required("Please enter the Seq Dashboard Name for this service")}
                      error={this.getFieldError("SeqDashboardName")}
                />
            </ExpandableFormSection>
        </div>;
    }

    private delete = async () => {
        await repository.DynamicWorkerServices.remove(this.service as DynamicWorkerServiceResource);
        this.setState({redirectTo: dynamicWorkerServicesRouteLinks.root});
        return true;
    };

    private handleSaveClick = () => {
        return this.doBusyTask(async () => { 
            await repository.DynamicWorkerServices.update(this.service.Id, this.state.model as UpdateDynamicWorkerServiceResource);
            this.setState({redirectTo: dynamicWorkerServicesRouteLinks.root});
        });
    };
}
