import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {HostedInstanceUsageMetricsResource} from "client/resources/hostedInstanceUsageMetricsResource";
import client from "client/client";
import {CreateHostedInstanceRequest} from "client/resources/createHostedInstanceRequest";
import {HostedInstanceReprovisioningRequest} from "client/resources/hostedInstanceReprovisioningRequest";
import {HostedInstanceOutageWindowRequest} from "client/resources/hostedInstanceOutageWindowRequest";
import {HostedInstanceLiveInformationResponse} from "client/resources/hostedInstanceLiveInformationResponse";
import {FileShareSnapshotResource} from "client/resources/fileShareSnapshotResource";
import {FileShareRestoreRequest} from "client/resources/fileShareRestoreRequest";
import {DatabaseRestoreRequest} from "client/resources/databaseRestoreRequest";
import {HostedInstanceTemplate} from "client/resources/hostedInstanceTemplate";
import {ProcessDumpResource} from "client/resources/processDumpResource";
import {InstanceStatus} from "client/resources/instanceStatus";
import {HostedInstanceMoveReefRequest} from "client/resources/hostedInstanceMoveReefRequest";
import {RestartInstanceRequest} from "client/resources/restartInstanceRequest";
import {ListArgs} from "client/repositories/listArgs";
import {
    ChangeUpgradeStrategyRequest
} from "client/resources/changeUpgradeStrategyRequest";
import {HostedInstanceConfigureStorageRequest} from "client/resources/hostedInstanceConfigureStorageRequest";
import {HostedInstanceConfigureDatabaseRequest} from "../resources/hostedInstanceConfigureDatabaseRequest";
import {MoveHostedInstancesRequest} from "client/resources/moveHostedInstancesRequest";
import { PagingResource } from "client/resources/pagingResource";
import {HostedInstanceRunSqlRequest} from "../resources/hostedInstanceRunSqlRequest";
import {HostedInstanceMasterKeyRequest} from "../resources/hostedInstanceMasterKeyRequest";
import {UpgradeStrategyType} from "../resources/upgradeStrategy";
import {HostedInstanceUpgradeRequest} from "../resources/hostedInstanceUpgradeRequest";
import { HostedInstanceAllowLocalHostOctopusPortalSignInRequest } from "client/resources/HostedInstanceAllowLocalHostOctopusPortalSignInRequest";
import { HostedInstanceInstallLegacyCloudAuthProvidersRequest } from "client/resources/HostedInstanceInstallLegacyCloudAuthProvidersRequest";
import { HostedInstanceRotateSqlLoginsRequest } from "client/resources/HostedInstanceRotateSqlLoginsRequest";
import { HostedInstanceRotateAdminApiKeyRequest } from "client/resources/HostedInstanceRotateAdminApiKeyRequest";
import { HostedInstanceDatabaseScaleRequest } from "client/resources/HostedInstanceConfigureDatabaseScaleRequest";
import { BulkUpgradeHostedInstanceVersionRequest } from "client/resources/BulkUpgradeHostedInstanceVersionRequest";
import { InstanceTaskLockResource } from "client/resources/instanceTaskLockResource";
import { SearchTaskLogRequest } from "client/resources/SearchTaskLogRequest";
import { MoveFileStorageRequest } from "client/resources/moveFileStorageRequest";
import {DiagnosticFileResource} from "../resources/diagnosticFileResource";
import { HostedInstanceActiveSqlTransactionsResponse } from "client/resources/HostedInstanceActiveSqlTransactionsResponse";
import {HostedInstanceDatabasePoolSizeRequest} from "../resources/HostedInstanceDatabasePoolSizeRequest";
import {TakeProcessDumpRequest} from "../resources/takeProcessDumpRequest";
import {HostedInstanceChangeDnsPrefixRequest} from "../resources/hostedInstanceChangeDnsPrefixRequest";
import {GatherTaskLogMetricsRequest} from "../resources/GatherTaskLogMetricsRequest";
import {HostedInstanceUpgradeHistoryResource} from "../resources/hostedInstanceUpgradeHistoryResource";
import {HostedInstanceBulkReprovisionRequest} from "client/resources/hostedInstanceBulkReprovisionRequest";
import {BackgroundTaskResource} from "client/resources/backgroundTaskResource";
import {HostedInstanceDowngradeRequest} from "../resources/hostedInstanceDowngradeRequest";
import {HostedInstanceDatabaseLimits} from "../resources/hostedInstanceDatabaseLimits";
import { CreateHostedBranchInstanceRequest } from "client/resources/createHostedBranchInstanceRequest";
import {HostedBranchInstanceResource} from "client/resources/hostedBranchInstanceResource";
import {ChangeCleanUpStrategyRequest} from "../resources/changeCleanUpStrategyRequest";
import {CleanUpStrategyType} from "../resources/cleanUpStrategy";
import {HostedBranchInstanceLicenseResource} from "../resources/hostedBranchInstanceLicenseResource";
import { InstanceLimitResource } from "client/resources/instanceLimitResource";
import {HostedInstanceDatabaseMaintenanceRequest} from "../resources/hostedInstanceDatabaseMaintenanceRequest";
import {HostedInstanceLookupResource} from "../resources/hostedInstanceLookupResource";
import {BulkRevokeUsersSessionsRequest} from "../resources/bulkRevokeUsersSessionsRequest";
import {HostedInstanceConfigureReplicasRequest} from "../resources/HostedInstanceConfigureReplicasRequest";
import {HostedInstanceBulkRotateAdminApiKeyRequest} from "../resources/HostedInstanceBulkRotateAdminApiKeyRequest";
import {HostedInstanceRotateMasterKeyRequest} from "../resources/HostedInstanceRotateMasterKeyRequest";
import {HostedInstanceBulkRotateMasterKeyRequest} from "../resources/HostedInstanceBulkRotateMasterKeyRequest";
import {BulkChangeUpgradeStrategyRequest} from "../resources/bulkChangeUpgradeStrategyRequest";
import {HostedInstanceSecretRotationHistoryResource} from "../resources/hostedInstanceSecretRotationHistoryResource";

export interface HostedInstanceListArgs {
    shortId?: string;
    dnsPrefix?: string;
    isInPool?: string;
    shouldBeMonitored?: string;
    version?: string;
    reefs: string[];
    envVariable?: string;
    kubernetesClusters: string[];
    storageAccounts: string[];
    upgradeStrategies: UpgradeStrategyType[];
    statuses: InstanceStatus[];
    betterUptimeStatus?: string;
    instanceSecondaryUptimeStatus?: string;
    cleanUpStrategies: CleanUpStrategyType[];
    taskCaps: string[];
    skip: string;
}

export interface HostedInstanceTaskLockListArgs {
    relatedToHostedInstanceIds?: string[];
    relatedToTaskId?: string;
}

export default class HostedInstanceRepository {

    private rootUri = "/api/hosted-instances";

    create(request: CreateHostedInstanceRequest) {
        return client.post<HostedInstanceResource>(this.rootUri, request);
    }

    createBranch(request: CreateHostedBranchInstanceRequest) {
        return client.post<HostedBranchInstanceResource>(`${this.rootUri}/branch-instance`, request);
    }

    list(args?: Partial<HostedInstanceListArgs> | ListArgs): Promise<PagingResource<HostedInstanceResource>> {
        return client.get(`${this.rootUri}{?skip,take,shortId,dnsPrefix,isInPool,version,reefs,envVariable,kubernetesClusters,storageAccounts,rings,statuses,outageWindow,betterUptimeStatus,instanceSecondaryUptimeStatus,upgradeStrategies,cleanUpStrategies,taskCaps,shouldBeMonitored}`, {...args});
    }

    getQueryStringForCsv(args?: Partial<Omit<HostedInstanceListArgs, "skip" | "take">> | ListArgs): string {
        return client.resolve(`{?shortId,dnsPrefix,isInPool,version,reefs,envVariable,kubernetesClusters,storageAccounts,rings,statuses,outageWindow,betterUptimeStatus,instanceSecondaryUptimeStatus,upgradeStrategies,cleanUpStrategies,taskCaps,shouldBeMonitored}`, args)
    }

    lookup() {
        return client.get<HostedInstanceLookupResource[]>(`${this.rootUri}/lookup`);
    }

    get(id: string) {
        return client.get<HostedInstanceResource>(`${this.rootUri}/{id}`, {id});
    }

    getUsage(id: string) {
        return client.get<HostedInstanceUsageMetricsResource>(`${this.rootUri}/{id}/usage-metrics`, {id});
    }

    runSql(instance: HostedInstanceResource, request: HostedInstanceRunSqlRequest) {
        return client.post(`${this.rootUri}/{id}/sql`, request, {id: instance.Id});
    }

    changeMasterKey(instance: HostedInstanceResource, request: HostedInstanceMasterKeyRequest) {
        return client.post(`${this.rootUri}/{id}/master-key`, request, {id: instance.Id});
    }

    reprovision(instance: HostedInstanceResource, request: HostedInstanceReprovisioningRequest) {
        return client.post<HostedInstanceResource>(`${this.rootUri}/{id}/reprovisioning`, request, {id: instance.Id});
    }

    upgrade(instance: HostedInstanceResource, request: HostedInstanceUpgradeRequest) {
        return client.post<HostedInstanceResource>(`${this.rootUri}/{id}/upgrade`, request, {id: instance.Id});
    }

    downgrade(instance: HostedInstanceResource, request: HostedInstanceDowngradeRequest) {
        return client.post<HostedInstanceResource>(`${this.rootUri}/{id}/downgrade`, request, {id: instance.Id});
    }

    changeDnsPrefix(instance: HostedInstanceResource, request: HostedInstanceChangeDnsPrefixRequest) {
        return client.post<HostedInstanceResource>(`${this.rootUri}/{id}/dns-prefix`, request, {id: instance.Id});
    }

    live(id: string): Promise<HostedInstanceLiveInformationResponse> {
        return client.get<HostedInstanceLiveInformationResponse>(`${this.rootUri}/{id}/live-information`, {id});
    }

    activeTransactions(id: string): Promise<HostedInstanceActiveSqlTransactionsResponse> {
        return client.get<HostedInstanceActiveSqlTransactionsResponse>(`${this.rootUri}/{id}/active-transactions`, {id});
    }

    changeOutageWindow(instance: HostedInstanceResource, request: HostedInstanceOutageWindowRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/outage-window`, request, {id: instance.Id});
    }

    changeUpgradeStrategy(instance: HostedInstanceResource, request: ChangeUpgradeStrategyRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/upgrade-strategy`, request, {id: instance.Id});
    }

    moveReef(instance: HostedInstanceResource, request: HostedInstanceMoveReefRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/move-reef`, request, {id: instance.Id});
    }

    configureStorage(instance: HostedInstanceResource, request: HostedInstanceConfigureStorageRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/file-share/quota`, request, {id: instance.Id});
    }

    getDatabaseLimits(id: string) {
        return client.get<HostedInstanceDatabaseLimits>(`${this.rootUri}/{id}/database/max-size`, {id});
    }

    configureDatabase(instance: HostedInstanceResource, request: HostedInstanceConfigureDatabaseRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/database/max-size`, request, {id: instance.Id});
    }

    configureDatabasePoolSize(instance: HostedInstanceResource, request: HostedInstanceDatabasePoolSizeRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/database/pool-size`, request, {id: instance.Id});
    }

    scaleDatabase(instance: HostedInstanceResource, request: HostedInstanceDatabaseScaleRequest): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/database/scale`, request, {id: instance.Id});
    }

    rotateSqlLogins(instance: HostedInstanceResource, request: HostedInstanceRotateSqlLoginsRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/{id}/database/rotate-sql-logins`, request, {id: instance.Id});
    }

    rotateMasterKey(instance: HostedInstanceResource, request: HostedInstanceRotateMasterKeyRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/{id}/rotate-master-key`, request, {id: instance.Id});
    }

    rotateAdminApiKey(instance: HostedInstanceResource, request: HostedInstanceRotateAdminApiKeyRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/{id}/rotate-admin-apikey`, request, {id: instance.Id});
    }

    changeAllowLocalHostOctopusPortalSignIn({ instance, request }: { instance: HostedInstanceResource; request: HostedInstanceAllowLocalHostOctopusPortalSignInRequest; }): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/allow-localhost-signin`, request, {id: instance.Id});
    }

    installLegacyCloudAuthProviders({ instance, request }: { instance: HostedInstanceResource; request: HostedInstanceInstallLegacyCloudAuthProvidersRequest; }): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/install-legacy-cloud-auth-providers`, request, {id: instance.Id});
    }

    delete(instance: HostedInstanceResource) {
        return client.del(`${this.rootUri}/{id}`, {args: {id: instance.Id}});
    }

    deactivate(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/deactivation`, null, {id: instance.Id});
    }

    reactivate(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/reactivation`, null, {id: instance.Id});
    }

    revokeUsersSessions(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/revoke-users-sessions`, null, { id: instance.Id });
    }

    cancelDatabaseMaintenance(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/database-maintenance/cancel`, null, { id: instance.Id });
    }

    runDatabaseMaintenance(id: string, request: HostedInstanceDatabaseMaintenanceRequest) {
        return client.post(`${this.rootUri}/{id}/database-maintenance`, request, {id});
    }

    restart(id: string, request: RestartInstanceRequest): Promise<string> {
        return client.post(`${this.rootUri}/{id}/restart`, request, {id});
    }

    azureAuth(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/azure-auth`, null, {id: instance.Id});
    }

    moveFileStorage(id: string, request: MoveFileStorageRequest) {
        return client.post(`${this.rootUri}/{id}/file-storage/move`, request, {id});
    }

    fileShareSnapshots(id: string) {
        return client.get<FileShareSnapshotResource[]>(`${this.rootUri}/{id}/file-share/snapshots`, {id});
    }

    snapshotFileShare(id: string) {
        return client.post(`${this.rootUri}/{id}/file-share/snapshot`, null, {id});
    }

    restoreFileShare(instance: HostedInstanceResource, request: FileShareRestoreRequest) {
        return client.post(`${this.rootUri}/{id}/file-share/restore`, request, {id: instance.Id});
    }

    takeProcessDump(id: string, request: Partial<TakeProcessDumpRequest>): Promise<ProcessDumpResource> {
        return client.post(`${this.rootUri}/{id}/take-process-dump`, request, {id});
    }

    restoreDatabase(instance: HostedInstanceResource, request: DatabaseRestoreRequest) {
        return client.post(`${this.rootUri}/{id}/database/restore`, request, {id: instance.Id});
    }

    getInstanceTemplates(): Promise<HostedInstanceTemplate[]> {
        return client.get(`${this.rootUri}/instance-templates`);
    }

    bulkChangeUpgradeStrategy(request: BulkChangeUpgradeStrategyRequest) {
        return client.post(`${this.rootUri}/upgrade-strategy`, request);
    }

    bulkUpgradeVersion(request: BulkUpgradeHostedInstanceVersionRequest) {
        return client.post(`${this.rootUri}/upgrade`, request);
    }

    bulkReprovision(request: HostedInstanceBulkReprovisionRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/reprovisioning`, request);
    }

    bulkRotateAdminApiKeys(request: HostedInstanceBulkRotateAdminApiKeyRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/rotate-admin-apikey`, request);
    }

    bulkRotateMasterKeys(request: HostedInstanceBulkRotateMasterKeyRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/rotate-master-key`, request);
    }

    bulkRotateSqlLogins(request: HostedInstanceBulkRotateAdminApiKeyRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/database/rotate-sql-logins`, request);
    }

    bulkRevokeUsersSessions(request: BulkRevokeUsersSessionsRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/revoke-users-sessions`, request);
    }

    bulkMigrateDeactivatedInstanceDatabases(request: HostedInstanceBulkReprovisionRequest): Promise<BackgroundTaskResource> {
        return client.post(`${this.rootUri}/database/migrate-deactivated-databases`, request);
    }

    moveHostedInstances(request: MoveHostedInstancesRequest) {
        return client.post(`${this.rootUri}/move-hosted-instances`, request);
    }

    searchTaskLogs(request: SearchTaskLogRequest) {
        return client.post(`${this.rootUri}/task-log/search`, request);
    }

    gatherTaskLogMetrics(request: GatherTaskLogMetricsRequest) {
        return client.post(`${this.rootUri}/task-log/metrics`, request);
    }

    linkToSystemReport(instanceId: string) {
        return client.resolve(`${this.rootUri}/{id}/system-report`, {id: instanceId});
    }

    getInstanceTaskLocks(args: HostedInstanceTaskLockListArgs | ListArgs): Promise<PagingResource<InstanceTaskLockResource>> {
        return client.get(`${this.rootUri}/instance-task-locks{?skip,take,relatedToHostedInstanceIds,relatedToTaskId}`, { ...args });
    }

    deleteInstanceTaskLock(lock: InstanceTaskLockResource) {
        return client.del(`${this.rootUri}/{id}/instance-task-lock/{lockOwnerId}`, {
            args: {id: lock.HostedInstanceId, lockOwnerId: lock.LockOwnerId}
        });
    }

    getUpgradeHistory(instanceId: string): Promise<HostedInstanceUpgradeHistoryResource[]> {
        const defaultQueryArgs = {take: 100, skip: 0};
        return client.get(`${this.rootUri}/{id}/upgrade-history`, {id: instanceId, ...defaultQueryArgs});
    }
    
    getSecretRotationHistory(instanceId: string): Promise<HostedInstanceSecretRotationHistoryResource[]> {
        const defaultQueryArgs = {take: 100, skip: 0};
        return client.get(`${this.rootUri}/{id}/secret-rotation-history`, {id: instanceId, ...defaultQueryArgs});
    }

    getDiagnosticFiles(instanceId: string) : Promise<DiagnosticFileResource[]> {
        return client.get(`${this.rootUri}/{id}/diagnostic-files`, {id: instanceId});
    }

    getUrlToDiagnosticFileContent(instanceId: string, file: DiagnosticFileResource, justification: string) {
        return client.resolve(`${this.rootUri}/{id}/diagnostic-files/content{?relativePath,justification}`, {id: instanceId, relativePath: file.RelativePath, justification});
    }

    deleteDiagnosticFile(instanceId: string, file: DiagnosticFileResource) {
        return client.del(`${this.rootUri}/{id}/diagnostic-files{?relativePath}`, {
            args: {id: instanceId, relativePath: file.RelativePath}
        });
    }

    getForLimit(instanceLimitId: string) {
        return client.get<HostedInstanceResource>(`${this.rootUri}/instance-limit/{instanceLimitId}`, {instanceLimitId});
    }

    getForSubscription(subscriptionId: string) {
        return client.get<HostedInstanceResource>(`${this.rootUri}/cloud-subscription/{subscriptionId}`, {subscriptionId})
    }

    changeCleanUpStrategy({ instance, request }: { instance: HostedInstanceResource; request: ChangeCleanUpStrategyRequest; }): Promise<HostedInstanceResource> {
        return client.post(`${this.rootUri}/{id}/clean-up-strategy`, request, {id: instance.Id});
    }

    getDefaultBranchInstanceLicense() {
        return client.get<HostedBranchInstanceLicenseResource>(`${this.rootUri}/default-branch-instance-license`)
    }

    changeBranchInstanceLicense(instance: HostedInstanceResource, request: HostedBranchInstanceLicenseResource) {
        return client.post<InstanceLimitResource>(`${this.rootUri}/{id}/branch-instance-license`, request, {id: instance.Id})
    }

    adminUser(instance: HostedInstanceResource) {
        return client.post(`${this.rootUri}/{id}/admin-user`, undefined, {id: instance.Id})
    }
    configureReplicas(instance: HostedInstanceResource, request: HostedInstanceConfigureReplicasRequest) {
        return client.post(`${this.rootUri}/{id}/replicas`, request, {id: instance.Id})
    }
}
