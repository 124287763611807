import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import * as React from "react";
import { HostedInstanceBulkReprovisionRequest } from "client/resources/hostedInstanceBulkReprovisionRequest";
import ReefMultiSelect from "shared/ReefMultiSelect";
import { ReefResource } from "client/resources/reefResource";
import { InstanceEnvironmentVariable } from "client/resources/instanceEnvironmentVariablesResource";
import InternalRedirect from "components/Navigation/InternalRedirect";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import Callout, {CalloutType} from "components/Callout";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
}

interface State extends DataBaseComponentState {
    reefs: Map<string, ReefResource>;
    instanceEnvironmentVariables: InstanceEnvironmentVariable[];
    request: HostedInstanceBulkReprovisionRequest;
    redirectTo?: string;
}

export default class BulkMigrateDeactivatedInstanceDatabasesDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            reefs: new Map<string, ReefResource>(),
            instanceEnvironmentVariables: [],
            request: {
                ReefIds: [],
                WithoutInstanceEnvVariableIds: [],
                ReprovisionDuringWeekends: true
            },
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const reefs = repository.Reefs.map();

            this.setState({
                reefs: await reefs,
                instanceEnvironmentVariables: [],
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <SaveDialogLayout title={`Bulk Migrate Deactivated Instance Databases`}
            saveButtonLabel={"Run task"}
            onSaveClick={() => this.bulkMigrateDeactivatedInstanceDatabases()}
            errors={this.state.errors}
            busy={this.state.busy}>
            <Callout type={CalloutType.Information} title="Confirm Feature Flags are set correctly">
                All deactivated instances with the <strong>Use the vCore purchasing model for instance
                database</strong> Portal feature flag enabled will have their database migrated to an equivalent vCore
                service level.
                <br/><br/>
                Instances excluded from the <strong>Participate in Bulk Reprovisioning</strong> Portal Feature Flag will
                not be migrated. These instances will need to be migrated manually.
            </Callout>

            <ReefMultiSelect
                items={this.state.reefs ? Array.from(this.state.reefs.values()) : []}
                value={this.state.request.ReefIds}
                onChange={ReefIds => this.updateRequest({ReefIds})}
            />
            <p>
                Instance databases will be migrated only if they are on the selected reefs. If no reef has been selected, all instances will be migrated.
            </p>
        </SaveDialogLayout>;
    }

    private updateRequest(update: Partial<HostedInstanceBulkReprovisionRequest>) {
        return this.setState(prevState => ({request: {...prevState.request, ...update}}));
    }

    private async bulkMigrateDeactivatedInstanceDatabases(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const backgroundTask = await repository.HostedInstances.bulkMigrateDeactivatedInstanceDatabases(this.state.request);
            this.setState({redirectTo: backgroundTasksRouteLinks.task(backgroundTask.Id).root})
        });
    }
}
