enum DashboardMetricType {
  DynamicWorkersInitialLeaseTime,
  InstanceFileUsage,
  InstanceDatabaseSizeUsage,
  InstanceCpuUsage,
  InstanceMemoryUsage,
  InstanceUptime,
  AzureResourceManagerThrottleData,
  RelativeInstanceDatabaseCpuUsage,
  RelativeInstanceCpuUsage,
  RelativeInstancePodMemoryUsage,
  PodRestarts,
  ProblematicInstanceTaskLocks,
}

export default DashboardMetricType;
