import * as React from "react";
import {Switch} from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import DynamicWorkerServiceLayout from "areas/dynamicWorkerServices/DynamicWorkerServiceLayout";
import DynamicWorkerServices from "areas/dynamicWorkerServices/DynamicWorkerServices";
import {EditDynamicWorkerService} from "areas/dynamicWorkerServices/EditDynamicWorkerService";
import {CreateDynamicWorkerService} from "./CreateDynamicWorkerService";

const DynamicWorkerServicesLayout: React.FunctionComponent<{}> = _ =>
    <Switch>
        <ReloadableRoute path={dynamicWorkerServicesRouteLinks.create} exact={true} component={CreateDynamicWorkerService}/>
        <ReloadableRoute path={dynamicWorkerServicesRouteLinks.root} exact={true} component={DynamicWorkerServices}/>
        <ReloadableRoute path={dynamicWorkerServicesRouteLinks.service(":id").root} component={DynamicWorkerServiceLayout}/>
        <ReloadableRoute render={() => <InternalRedirect to={dynamicWorkerServicesRouteLinks.root}/>}/>
    </Switch>;

export default DynamicWorkerServicesLayout;
